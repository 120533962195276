thead, #thFooter, #tfoot {
    color: white;
    background-color:#343a40;
  }

  tr:nth-child(even) {
    background-color: whitesmoke;
  }

  #rowFiliale{
   width: 68%;
  }

  #BTN-Export{
    float: right;
  }